export const GTMPageView = (title: string, prevUrl?: string) => {
  interface PageEventProps {
    event: string
    referrer: string
    pageTitle: string
    pagePath: string
    originalLocation: string
  }

  const pageEvent: PageEventProps = {
    event: 'PageviewSPA',
    referrer: prevUrl || window.location.href,
    pageTitle: title,
    pagePath: document.location?.pathname,
    originalLocation: document.location.protocol + '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search
  }

  if (window?.dataLayer) {
    window?.dataLayer?.push(pageEvent)
  }

  return pageEvent
}
