import React from "react";
import styles from '@/public/styles/components/grid/grid-list.module.scss'
import PreviewDefault from "@/components/common/list/PreviewDefault";

const GridList = (props) => {
    const {items} = props

    return (
        <div>
            <div className={styles.gridList}>
                {items?.map((item) => {
                        return (
                            <PreviewDefault key={item._id} item={item}/>
                        );
                    }
                )}
            </div>
        </div>
    );
}

export default GridList