import React from "react";
import homeStyles from "@/public/styles/pages/home/main.module.scss";
import Link from 'next/link'

const TopTags = (props) => {
    const {tags} = props

    return (
        <>
            <span className={homeStyles.titleh3}>Рекомендуемые темы</span>
            <div className={homeStyles.topTags}>
                {
                    tags?.map((tag) => {
                        return (
                            <Link key={tag.urlKey} href={`/blog/tag/${tag.urlKey}`}><a>{tag.name}</a></Link>
                        )
                    })
                }
            </div>
        </>
    );
}

export default TopTags