import Link from 'next/link'
import styles from '@/public/styles/components/grid/grid-preview.module.scss'
import Image from 'next/image';

import React from "react";

const IMAGE_HOST = process.env.NEXT_PUBLIC_API_IMAGE_URL

function cut(text, limit) {
    text = text.trim();
    if( text.length <= limit) return text;

    text = text.slice(0, limit);

    return text.trim() + "...";
}

const PreviewDefault = ({item}) => {

    return (
        <div className={styles.itemPreview}>
            <div className={styles.itemImage}>
                <Link href={`${item.link}`}>
                    <a>
                        {item.previewImage &&
                        <Image
                            quality={100}
                            src={`${IMAGE_HOST}${item.previewImage}`}
                            alt={`${item.name}`}
                            width={405}
                            height={245}
                        />
                        }
                        {!item.previewImage &&
                        <Image
                            quality={100}
                            src='/images-local/post-placeholder-405.png'
                            alt={`${item.name}`}
                            width={405}
                            height={245}
                        />
                        }
                    </a>
                </Link>
            </div>
            <div className={styles.itemDetails}>
                {(item.category?.name) &&
                <span className={styles.postCategory}>
                    <span className={styles.dot} style={{background: item.category?.dotColor}}/>
                    <Link href={item.category.link}><a>{item.category?.name}</a></Link>
                </span>
                }
                <div className={styles.itemName}>
                    <Link href={item.link}><a>{item.name}</a></Link>
                </div>
                <div className={styles.itemDescription}>
                    {cut(item.contentShort.replace(/<[^>]+>/g, '').replace('&nbsp;', ' '), 90)}
                </div>
            </div>
            {
                item?.blocked &&
                <div className={styles.blocked} >
                    <div className={styles.blockDescr}>
                        <img src={'/images-local/icon/blocked.svg'} alt={'blocked'}/>
                        <span>Заблокировано</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default PreviewDefault