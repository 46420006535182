import {connect, useDispatch} from 'react-redux'
import {wrapper} from 'store/store'
import SeoData from '@/components/core/SeoData'
import TopPosts from "@/components/home/TopPosts";
import styles from '@/public/styles/common/content.module.scss'
import stylesHome from '@/public/styles/pages/home/main.module.scss'
import fetcher from "../lib/utils/fetcher";
import getConfig from "next/config";
import GridList from "@/components/common/list/GridList";
import MostRead from "@/components/home/MostRead";
import TopTags from "@/components/home/TopTags";

import React, {useEffect} from "react";
import {setBlogCategory} from "store/blog/action";
import {setHomePage} from 'store/page/action'
import {request} from "@/lib/utils/request";
const env = process.env


export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
    ctx.res.setHeader(
        "Cache-Control",
        "max-age=300, stale-while-revalidate=60, stale-if-error=86400"
    );

    const fetchHomePageUrl = `${env.NEXT_PUBLIC_API_URL}/api/home-page`
    const homePage = await fetcher(fetchHomePageUrl);
    store.dispatch(setHomePage(homePage))

    const topTags = await request('/api/blog/top-tags?size=10', 'GET', null, true)
    const topWeekPosts = await request('/api/blog/top-week-posts?size=10', 'GET', null, true)

    const fetchNewPostsUrl = `${env.NEXT_PUBLIC_API_URL}/api/blog/new-posts?size=8`
    const newPosts = await fetcher(fetchNewPostsUrl);

    let newPostsPreviewList = newPosts.map((item) => {
        return {
            '_id': item._id,
            'name': item.name,
            'category': {
                'name': item.category.name,
                'link': `/blog/category/${item.category.urlKey}`,
                'dotColor': item.category.dotColor,
            },
            'previewImage': item.previewImage?.fileUrl || null,
            'contentShort': item.contentShort,
            'link': `/blog/post/${item.urlKey}`,
        };
    })

    return {
        props: {
            topTags: topTags,
            newPostsPreviewList: newPostsPreviewList,
            topWeekPosts: topWeekPosts,
            homePage: homePage
        }
    };
})

function Home(props) {
    const {topTags, newPostsPreviewList, topWeekPosts, homePage} = props
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBlogCategory(null))
    })

    return (
        <>
            <SeoData title={homePage.metaTitle} description={homePage.metaDescription} keywords={homePage.metaKeyword}/>

            <div className={styles.contentContainer}>
                {
                    (topWeekPosts.length > 0) &&
                    <TopPosts posts={topWeekPosts}/>
                }
                <div className={stylesHome.twoColumnsContainer}>
                    <div className={stylesHome.newPostsContainer}>
                        {
                            newPostsPreviewList.length > 0 &&
                            <div>
                                <span className={stylesHome.title}>Недавние публикации</span>
                                <GridList items={newPostsPreviewList}/>
                            </div>
                        }

                    </div>
                    <div className={stylesHome.homeSidebar}>
                        <div className={stylesHome.topTagsContainer}>
                            {
                                (topTags.length > 0) &&
                                <TopTags tags={topTags}/>
                            }
                        </div>
                    </div>
                </div>
                <div className={stylesHome.pageDescription}>
                    <h1>{homePage.pageTitle}</h1>
                    <div dangerouslySetInnerHTML={{__html: homePage.pageContent}}/>
                    {homePage.pageContentLink && <a href={homePage.pageContentLink}>Подробнее</a>}
                </div>
            </div>
        </>
    )
}

export default connect(null)(Home)
