import Head from 'next/head'
import {useRouter} from 'next/router'
import {isUserAgentBotSelector, ssrHostSelector, ssrRequestUrlSelector} from '@/store/core/selector'
import {useSelector} from 'react-redux'
import GtmHead from '@/components/core/GtmHead'
import {useEffect, useState} from 'react'
import {GTMPageView} from '@/lib/utils/gtm/gtm'

const SeoData = (props) => {
    const router = useRouter()

    const ssrHost = useSelector(ssrHostSelector)
    const ssrRequestUrl = useSelector(ssrRequestUrlSelector)
    const isUserAgentBot = useSelector(isUserAgentBotSelector)

    const [pageViewSPAPath, setPageViewSPAPath] = useState(router?.asPath)

    useEffect(() => {
        setTimeout(() => window.prevUrl = window.location.href, 1000)
    }, [router.asPath])

    const {
        description,
        title,
        article = {},
        image = `https://${ssrHost}/images-local/logo-colored.svg`,
        ogType = 'website',
        hideFromRobots = false
    } = props

    useEffect(() => {
        if (typeof window !== 'undefined') {
            GTMPageView(title, window.prevUrl)
        }
    }, [pageViewSPAPath])

    useEffect(() => {
        setPageViewSPAPath(router.asPath)
    }, [router.asPath])

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description}/>

            {ogType == 'website' && <>
                <meta property="og:type" content="website"/>
            </>
            }

            {ogType == 'article' && <>
                {
                    article.tags.map(item => {
                        return (
                            <meta property="article:tag" content={item.name} key={item.urlKey}/>
                        )
                    })
                }
                <meta property="og:type" content="article"/>
                <meta property="article:section" content={article?.category} />
                <meta property="article:published_time" content={article.created_at} />
                <meta property="article:modified_time" content={article.updated_at} />
                <meta name="author" content="Редакция Wayout" />
                <meta name="copyright" content="Все права принадлежат Wayout" />
            </>
            }

            <meta property="og:url" content={ssrRequestUrl?.replace('http://', 'https://')}/>
            <meta property="og:image" content={`${image}`}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:locale" content={'ru_RU'}/>
            <meta property="og:site_name" content='Wayout Fitness'/>

            <link
                key={title}
                rel="canonical"
                href={ssrRequestUrl?.replace('http://', 'https://')}
            />

            {hideFromRobots && (
                <meta name="robots" content="noindex, nofollow, noimageindex, noarchive, nosnippet"/>
            )}

            {!isUserAgentBot && process.env.NEXT_PUBLIC_GTM_ID != 'TODO' && (
                <GtmHead
                    gtmId={process.env.NEXT_PUBLIC_GTM_ID}
                />
            )}
        </Head>
    )
}
export default SeoData
