import React from "react";
import homeStyles from "@/public/styles/pages/home/main.module.scss";
import PreviewSlider from "@/components/common/PreviewSlider";


const TopsPosts = (props) => {
    const {posts} = props

    let items = posts.map((post) => {
        return {
            '_id': post._id,
            'name': post.name,
            'category': {
                'name': post.category.name,
                'link': `/blog/category/${post.category.urlKey}`,
                'dotColor': post.category.dotColor,
            },
            'previewImage': post.previewImage?.fileUrl,
            'contentShort': post.contentShort,
            'link': `/blog/post/${post.urlKey}`,
        };
    })

    return (
        <div className={homeStyles.topPostsContainer}>
            <span className="title-h2">Лучшее на этой неделе</span>
            <div className={homeStyles.topPostsSlider}>
                <PreviewSlider posts={items}/>
            </div>
        </div>
    );
}

export default TopsPosts