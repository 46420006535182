import React from "react";
import PreviewDefault from 'components/common/list/PreviewDefault'
import styles from '@/public/styles/components/post-slider.module.scss'
import Slider from "react-slick";

const PreviewSlider = (props) => {
    const {posts} = props

    let slider;

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className={styles.sliderNavigation}>
                <div data-element={`sliderPrev`} className={styles.sliderPrev} onClick={() => {
                    slider.slickPrev();
                }}>

                </div>
                <div data-element={`sliderNext`} className={styles.sliderNext} onClick={() => {
                    slider.slickNext();
                }}>

                </div>
            </div>
            <Slider ref={c => (slider = c)} {...settings} className="posts-slider">
                {posts?.map((post) => {
                        return (
                            <div className="post-slide" key={post._id}>
                                <PreviewDefault item={post}/>
                            </div>
                        );
                    }
                )}
            </Slider>
        </>
    );
}

export default PreviewSlider